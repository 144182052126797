/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    pre: "pre",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "API requests are authorized using OAuth2 Bearer tokens (see ", React.createElement(_components.a, {
    href: "https://tools.ietf.org/html/rfc6749"
  }, "RFC 6749"), ")."), "\n", React.createElement(_components.p, null, "You can create multiple applications in Signatu. Each application is assigned\nclient credentials. Use these credentials to create a token at ", React.createElement(_components.code, null, "https://api.signatu.com/oauth/v0"), ":"), "\n", React.createElement(_components.p, null, "You can authorize the call by providing the ", React.createElement(_components.code, null, "client_id"), " and ", React.createElement(_components.code, null, "client_secret"), " in the payload:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ curl https://api.signatu.com/oauth/v0/token  \\\n    -d '{\"grant_type\": \"client_credentials\", \"scope\": \"consent\",\n        \"client_id\": \"1234\", \"client_secret\": \"5678\"}'\n    -H 'Content-Type: application/json'\n\n{   \"access_token\":\"21984329148923\",\n    \"expires_in\":315360000,\n    \"scope\":\"consent\",\n    \"refresh_token\":\"984723573289745\",\n    \"token_type\":\"Bearer\" }\n")), "\n", React.createElement(_components.p, null, "Or, you can Base64 encode the string ", React.createElement(_components.code, null, "client_id:client_secret"), ", and provide as a ", React.createElement(_components.code, null, "Authorization: Basic"), " header:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ echo -n 1234:5678|base64\nMTIzNC01Njc4\n$ curl https://api.signatu.com/oauth/v0/token \\\n    -d '{\"grant_type\": \"client_credentials\", \"scope\": \"consent\" }'\n    -H 'Authorization: Basic MTIzNC01Njc4'\n    -H 'Content-Type: application/json'\n\n{   \"access_token\":\"21984329148923\",\n    \"expires_in\":315360000,\n    \"scope\":\"consent\",\n    \"refresh_token\":\"984723573289745\",\n    \"token_type\":\"Bearer\" }\n")), "\n", React.createElement(_components.h2, {
    id: "using-refresh_token",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-refresh_token",
    "aria-label": "using refresh_token permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using ", React.createElement(_components.code, null, "refresh_token")), "\n", React.createElement(_components.p, null, "For subsequent requests you can use the ", React.createElement(_components.code, null, "refresh_token"), " to create a new bearer token for the same scope(s):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ curl https://api.signatu.com/oauth/v0/token \\\n    -d '{\"grant_type\": \"refresh_token\", \"refresh_token\": \"984723573289745\"}'\n    -H 'Authorization: Basic MTIzNC01Njc4'\n    -H 'Content-Type: application/json'\n\n{\"access_token\":\"21984329148923\",\n \"expires_in\":315360000,\n \"scope\":\"consent\",\n \"refresh_token\":\"984723573289745\",\n \"token_type\":\"Bearer\"}\n")), "\n", React.createElement(_components.h2, {
    id: "scopes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scopes",
    "aria-label": "scopes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scopes"), "\n", React.createElement(_components.p, null, "Signatu supports the following scopes for OAuth 2.0 tokens:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "consent"), " - create and read consent events."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "trackerdetect"), " - use the Trackerdetect API."), "\n"), "\n", React.createElement(_components.p, null, "All tokens will also receive the additional scope ", React.createElement(_components.code, null, "DEFAULT"), "  which is\nrequired for any access. the ", React.createElement(_components.code, null, "DEFAULT"), " scope will be added automatically."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
